import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editingOff, setActiveTask, updateTask } from "../store/todo/todoSlice";

export default function FormUpdate() {
  const dispatch = useDispatch();
  const activeTask = useSelector((state) => state.todo.activeTask);
  const [text, setText] = useState(activeTask.text);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim() === "") {
      setText("");
      return;
    }
    dispatch(updateTask({ id: activeTask.id, text: text.trim() }));
    dispatch(setActiveTask({ task: {} }));
    dispatch(editingOff());
  };

  return (
    <div>
      <form className='form-update' onSubmit={handleSubmit}>
        <input
          type='text'
          value={text}
          onChange={(e) => setText(e.target.value)}
          className='form-update__input'
          ref={inputRef}
        />
        <button type='submit' className='form-update__btn'>
          Save
        </button>
      </form>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import DeleteConfirm from "../components/DeleteConfirm";
import FormAdd from "../components/FormAdd";
import FormSearch from "../components/FormSearch";
import FormUpdate from "../components/FormUpdate";
import TaskList from "../components/TaskList";
import WelcomeMsg from "../components/WelcomeMsg";

export default function HomePage() {
  const usr = useSelector((state) => state.user);
  const isEditing = useSelector((state) => state.todo.isEditing);
  const isDeleteConfirmOpen = useSelector(
    (state) => state.todo.deleteConfirm.isOpen
  );

  return (
    <>
      {isDeleteConfirmOpen && <DeleteConfirm />}

      <div className='kontainer'>
        {!usr.isLoggedIn && <WelcomeMsg />}
        {usr.isLoggedIn && !isEditing && <FormAdd />}
        {usr.isLoggedIn && isEditing && <FormUpdate />}
        {usr.isLoggedIn && <FormSearch />}
        <TaskList />
      </div>
    </>
  );
}

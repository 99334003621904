import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import FootNav from "./components/FootNav";
import NavBar from "./components/NavBar";
import Notification from "./components/Notification";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";

import { checkLogin, userLoggedIn } from "./store/user/userSlice";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLogin());
  }, []);

  return (
    <>
      <Notification />
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/users/login' element={<Navigate replace to='/login' />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
}

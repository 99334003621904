import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTasks } from "../store/todo/todoSlice";
import TaskItem from "./TaskItem";

export default function TaskList() {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.todo.list);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const taskMenu = useSelector((state) => state.todo.itemMenu);
  const filteredBy = useSelector((state) => state.todo.filteredBy);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchTasks());
    }
  }, [isLoggedIn]);

  const taskTextContainsTerm = (t) =>
    t.text.toLowerCase().includes(filteredBy.text.toLowerCase());

  return (
    <div className='tasks-kontainer'>
      {isLoggedIn && tasks.length === 0 ? (
        <div className='no-tasks'>No tasks!</div>
      ) : (
        <ul className='task-list'>
          {tasks.filter(taskTextContainsTerm).map((t) => (
            <TaskItem
              key={t.id}
              task={t}
              menuOpened={taskMenu.isOpen && taskMenu.id === t.id}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  deleteConfirmClosed,
  setActiveTask,
} from "../store/todo/todoSlice";

export default function DeleteConfirm() {
  const dispatch = useDispatch();
  const activeTask = useSelector((state) => state.todo.activeTask);

  const handleConfirmClick = () => {
    dispatch(deleteTask(activeTask));
  };

  const handleCancelClick = (e) => {
    const elem = e.target;
    if (
      elem.classList.contains("delete-confirm") ||
      elem.classList.contains("delete-confirm__text")
    ) {
      return;
    }

    dispatch(deleteConfirmClosed());
    dispatch(setActiveTask({ task: {} }));
  };

  return (
    <div className='delete-confirm--base' onClick={handleCancelClick}>
      <div className='delete-confirm'>
        <span className='delete-confirm__text'>Delete it ?</span>
        <button
          className='delete-confirm__btn--yes'
          onClick={handleConfirmClick}>
          Sure!
        </button>
        <button className='delete-confirm__btn--no' onClick={handleCancelClick}>
          Nope!
        </button>
      </div>
    </div>
  );
}

import React from "react";
import FormLogin from "../components/FormLogin";

export default function LoginPage() {
  return (
    <div className='kontainer'>
      <h1 className='heading-h1'>Login</h1>
      <FormLogin />
    </div>
  );
}

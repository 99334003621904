export const apiEndpoints = {
  todo: {
    list: "/todo/",
    create: "/todo/create/",
    update: (taskId) => `/todo/update/${taskId}/`,
    delete: (taskId) => `/todo/delete/${taskId}/`,
  },
  users: {
    check_login: "/users/check-login/",
    login: "/users/login/",
    logout: "/users/logout/",
  },
};

import { configureStore as cS } from "@reduxjs/toolkit";
import todoReducer from "./todo/todoSlice";
import userReducer from "./user/userSlice";

export default function configureStore() {
  return cS({
    reducer: {
      todo: todoReducer,
      user: userReducer,
    },
  });
}

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setFilterText } from "../store/todo/todoSlice";
// import { debounce } from "../utils";
import { BiSearchAlt2 } from "react-icons/bi";

export default function FormSearch() {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const timeoutRef = useRef(null);

  const handleChange = (e) => {
    const val = e.target.value;
    setText(val);

    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (val.length > 0 && val.trim() === "") return;
      dispatch(setFilterText(val.trim()));
    }, 400);
  };

  return (
    <div>
      <form className='form-search'>
        <input
          type='text'
          className='form-search__input'
          value={text}
          onChange={handleChange}
          placeholder='Search...'
        />
        <div className='form-search__icon'>
          <BiSearchAlt2 />
        </div>
      </form>
    </div>
  );
}

import React from "react";

export default function MenuItem({ icon, text, danger, onClick }) {
  return (
    <li className={`menu-item ${danger ? "danger" : ""}`} onClick={onClick}>
      <div className='menu-item__icon'>{icon}</div>
      <div className='menu-item__text'>{text}</div>
    </li>
  );
}

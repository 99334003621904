import axios from "axios";

const instance = axios.create({
  baseURL: process.env.API_BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  timeout: 2000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;

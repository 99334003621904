import React, { useState } from "react";
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";

export default function Footer() {
  const [opened, setOpened] = useState(false);

  const handleExpandClick = (e) => {
    setOpened((prev) => !prev);
  };

  return (
    <footer className={`footer ${opened ? "opened" : ""}`}>
      <div className='footer__title'>
        <div className='footer__title__btn' onClick={handleExpandClick}>
          {opened ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}
        </div>
        <span>2021 &copy;</span>
        <a href={process.env.HOST} className='footer__blog'>
          hungntgrb
        </a>
      </div>

      <nav className='footer__nav'>
        <div>
          <a href={process.env.HOST} rel='noopener noreferrer'>
            &larr; Blog
          </a>
        </div>
      </nav>
    </footer>
  );
}

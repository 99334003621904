import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLogin } from "../store/user/userSlice";
import FieldError from "./FieldError";

export default function FormLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleSubmit = (values, actions) => {
    const { email, password } = values;
    dispatch(userLogin({ email, password }, navigate));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(userLogin({ email, password: pw }));
  //   setEmail("");
  //   setPw("");
  //   navigate("/");
  // };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email!")
      .max(50, "Too long!")
      .required("Email is required!"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long!")
      .max(40, "Too long!")
      .required("Password is required!"),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        <Form className='form-login'>
          <label htmlFor='email' className='form-login__label'>
            Email
          </label>
          <Field
            name='email'
            className='form-login__input'
            id='email'
            type='email'
            innerRef={emailRef}
            placeholder='email_address@somewhere.com'
          />
          <ErrorMessage name='email' component={FieldError} />

          <label htmlFor='password' className='form-login__label'>
            Password
          </label>
          <Field
            name='password'
            type='password'
            className='form-login__input'
            id='password'
            placeholder='password'
          />
          <ErrorMessage name='password' component={FieldError} />

          <button type='submit' className='form-login__btn'>
            Login
          </button>
        </Form>
      </Formik>
    </div>
  );
}

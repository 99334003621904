import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addTask } from "../store/todo/todoSlice";

export default function FormAdd() {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim().length !== 0) {
      dispatch(addTask({ text }));
    }
    setText("");
  };

  return (
    <div>
      <form className='form-add' onSubmit={handleSubmit}>
        <input
          type='text'
          value={text}
          onChange={(e) => setText(e.target.value)}
          className='form-add__input'
          ref={inputRef}
        />
        <button className='form-add__btn' type='submit'>
          Add
        </button>
      </form>
    </div>
  );
}

import React from "react";

export default function FootNav() {
  return (
    <nav class='foot-nav'>
      <div>
        <a href={process.env.HOST} rel='noopener noreferrer'>
          &larr; Blog
        </a>
      </div>
    </nav>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import configureStore from "./store/configureStore";

import "./styles/main.scss";

export const store = configureStore();

// document.addEventListener(
//   "keydown",
//   function (e) {
//     if (e.key === "/") {
//       document.querySelector(".form-search__input").focus();
//     } else if (e.key === ".") {
//       document.querySelector(".form-add__input").focus();
//     }
//   },
//   false
// );

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector("#__page")
);

import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../store/user/userSlice";
import {
  editingOff,
  setActiveTask,
  taskListReceived,
} from "../store/todo/todoSlice";

export default function NavBar() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);

  const handleLogoutClick = () => {
    dispatch(userLogout());
    dispatch(taskListReceived({ taskList: [] }));
    dispatch(editingOff());
    dispatch(setActiveTask({ task: {} }));
  };

  return (
    <nav className='navbar'>
      <div className='navbar__brand'>
        <Link to='/'>Wünderlist</Link>
      </div>

      <div className='navbar__right'>
        {userState.isLoggedIn && (
          <div className='navbar__username'>{userState.username}</div>
        )}

        {!userState.isLoggedIn && (
          <Link to='/login' className='login-btn'>
            Login
          </Link>
        )}
        {userState.isLoggedIn && (
          <button onClick={handleLogoutClick} className='logout-btn'>
            Logout
          </button>
        )}
      </div>
    </nav>
  );
}

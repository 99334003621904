export const MODE = "dev";
// const MODE = "prod";
// ----------------

const appConfig = {
  endpoints: {},
  notiDuration: 4000,
};

export default appConfig;

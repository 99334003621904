export const toastMessages = {
  login: {
    success: "Logged in successfully!",
    error: "Error log-in",
  },
  logout: {
    success: "Logged out successfully!",
    error: "Error log-out",
  },
  checkLogin: {
    success: "You're logged-in",
    error: "You're not logged-in",
  },
  toggleComplete: {
    success: "Toggled task",
    error: "Error toggle task",
  },
  updateTask: {
    success: "Task updated",
    error: "Error updating task",
  },
  deleteTask: {
    success: "Task deleted",
    error: "Error deleting task",
  },
  createTask: {
    success: "Task created",
    error: "Error creating task",
  },
};

import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { GoPencil, GoTrashcan } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  menuToggled,
  clickTask,
  // deleteTask,
  itemMenuClosed,
  deleteConfirmOpened,
  setActiveTask,
  editingOn,
} from "../store/todo/todoSlice";
import MenuItem from "./MenuItem";

export default function TaskItem({ task, menuOpened }) {
  const dispatch = useDispatch();

  const handleDoneClick = (task_) => {
    dispatch(clickTask(task_));
  };

  const handleMenuClick = (task_) => {
    dispatch(menuToggled({ taskId: task_.id }));
  };

  const handleDeleteClick = (task_) => {
    dispatch(setActiveTask({ task: task_ }));
    dispatch(deleteConfirmOpened());
  };

  const handleMouseLeaveMenu = (e) => {
    dispatch(itemMenuClosed());
  };

  const handleEditClick = (task_) => {
    dispatch(setActiveTask({ task: task_ }));
    dispatch(editingOn());
  };

  return (
    <li className={"task-item " + (task.done ? "done" : "")} tabIndex='0'>
      <div className='task-item__text' onClick={() => handleDoneClick(task)}>
        {task.text}
      </div>
      <div
        className='task-item__btn'
        onClick={() => handleMenuClick(task)}
        tabIndex='0'>
        <FiMoreVertical />
      </div>
      <ul
        className={`task-item__menu ${menuOpened ? "visible" : ""}`}
        onMouseLeave={handleMouseLeaveMenu}>
        <MenuItem
          icon={<GoPencil />}
          text={`Edit`}
          onClick={() => handleEditClick(task)}
        />
        <MenuItem
          icon={<GoTrashcan />}
          text={`Delete`}
          danger
          onClick={() => handleDeleteClick(task)}
        />
      </ul>
    </li>
  );
}

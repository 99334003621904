import appConfig from "./config";

export const debounce = (func, delay) => {
  let timeout;
  return (ev) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(ev), delay);
  };
};

class MyToast {
  constructor() {
    this.timeout = setTimeout(() => {}, 1000);
    this.notiDiv = null;
  }

  common(text) {
    clearTimeout(this.timeout);
    this.notiDiv = document.querySelector(".notification");
    this.notiDiv.innerHTML = text;
    this.notiDiv.classList.add("noti-visible");
    this.timeout = setTimeout(() => {
      this.notiDiv.classList.remove("noti-visible");
    }, appConfig.notiDuration);
  }

  danger(text) {
    this.common(text);
    this.notiDiv.setAttribute("data-level", "danger");
  }

  success(text) {
    this.common(text);
    this.notiDiv.setAttribute("data-level", "success");
  }

  warning(text) {
    this.common(text);
    this.notiDiv.setAttribute("data-level", "warning");
  }

  info(text) {
    this.common(text);
    this.notiDiv.setAttribute("data-level", "info");
  }
}

export const mytoast = new MyToast();

export function getCookie(cookieName) {
  let cookieStr = document.cookie;
  if (!cookieName) {
    console.error("Must provide cookie-name!");
    return "Missing cookie-name!";
  }
  const cn = cookieName.trim() + "=";
  const arr1 = cookieStr.split(";").map((i) => i.trim());
  let t;
  for (let n = 0; n < arr1.length; n++) {
    t = arr1[n];
    if (t.includes(cn)) {
      break;
    }
  }
  const value = t.split(cn)[1];
  return value;
}

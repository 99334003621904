import React from "react";

export default function NotFoundPage() {
  return (
    <main className='page-404'>
      <div className='page-404__num'>404</div>
      <div className='page-404__pnf'>Page Not Found</div>
    </main>
  );
}

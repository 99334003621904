import { createSlice } from "@reduxjs/toolkit";
import axios_ from "../../services/axiosService";
import { mytoast } from "../../utils";
import { apiEndpoints } from "../../apiEndpoints";
import { toastMessages } from "../../constants/toastMessages";

const initialState = {
  isLoggedIn: false,
  username: "",
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.isLoggedIn = true;
    },
    userLoggedOut: (state, action) => {
      state.isLoggedIn = false;
      state.username = "";
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
  },
});

export const { userLoggedIn, userLoggedOut, setUsername } = slice.actions;

export default slice.reducer;

export const userLogin = (credentials, navigateTo) => async (dispatch) => {
  try {
    const res = await axios_.post(apiEndpoints.users.login, credentials);
    dispatch(userLoggedIn());
    dispatch(setUsername(res.data.username));
    mytoast.success(toastMessages.login.success);
    navigateTo("/");
  } catch (error) {
    mytoast.danger(toastMessages.login.error);
  }
};

export const userLogout = () => async (dispatch) => {
  try {
    await axios_.post(apiEndpoints.users.logout);
    mytoast.success(toastMessages.logout.success);
  } catch (error) {
    mytoast.danger(toastMessages.logout.error);
  } finally {
    dispatch(userLoggedOut());
  }
};

export const checkLogin = () => async (dispatch) => {
  try {
    const res = await axios_.get(apiEndpoints.users.check_login);
    dispatch(userLoggedIn());
    dispatch(setUsername(res.data.username));
    mytoast.success(toastMessages.checkLogin.success);
  } catch (error) {
    mytoast.danger(toastMessages.checkLogin.error);
  }
};

import { createSlice } from "@reduxjs/toolkit";
import axios_ from "../../services/axiosService";
import { mytoast, getCookie } from "../../utils";
import { apiEndpoints } from "../../apiEndpoints";
import { toastMessages } from "../../constants/toastMessages";

const initialState = {
  sourceList: [],
  list: [],
  tempList: [],
  filtered: [],
  itemMenu: {
    isOpen: false,
    id: "",
  },
  deleteConfirm: {
    isOpen: false,
  },
  activeTask: {},
  isEditing: false,
  filteredBy: { text: "", done: null },
  sortedBy: { created: "desc" },
  // { updated: 'asc' }, { done: 'asc' }
};

const slice = createSlice({
  name: "todo",
  initialState,
  reducers: {
    taskListReceived: (todo, action) => {
      todo.list = action.payload.taskList;
      todo.sourceList = action.payload.taskList;
    },
    doneToggled: (todo, action) => {
      const idx = todo.list.findIndex((t) => t.id === action.payload.taskId);
      todo.list[idx].done = !todo.list[idx].done;
    },
    menuToggled: (todo, action) => {
      const { isOpen, id } = todo.itemMenu;
      if (!isOpen) {
        todo.itemMenu.isOpen = true;
        todo.itemMenu.id = action.payload.taskId;
      } else if (isOpen) {
        if (id === action.payload.taskId) {
          todo.itemMenu.isOpen = false;
        }
        todo.itemMenu.id = action.payload.taskId;
      }
    },
    itemMenuClosed: (state) => {
      state.itemMenu.isOpen = false;
    },
    menusClosed: (state) => {
      state.itemMenu.isOpen = false;
    },
    taskAdded: (todo, action) => {
      const task = {
        id: Date.now().toString(),
        text: action.payload.text,
        done: false,
        archived: false,
      };
      todo.list.unshift(task);
    },
    tasksCopied: (state, action) => {
      state.tempList = state.list;
    },
    tasksRestored: (state) => {
      state.list = state.tempList;
      state.tempList = [];
    },
    tempListCleared: (state) => {
      state.tempList = [];
    },
    taskDeleted: (state, action) => {
      const idx = state.list.findIndex((t) => t.id === action.payload.taskId);
      state.list.splice(idx, 1);
    },
    deleteConfirmOpened: (state) => {
      state.deleteConfirm.isOpen = true;
    },
    deleteConfirmClosed: (state) => {
      state.deleteConfirm.isOpen = false;
    },
    setActiveTask: (state, action) => {
      state.activeTask = action.payload.task;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setFilterText: (state, action) => {
      state.filteredBy.text = action.payload;
    },
    setFilterDone: (state, action) => {
      state.filteredBy.done = action.payload;
    },
  },
});

export const {
  taskListReceived,
  doneToggled,
  menuToggled,
  taskAdded,
  tasksCopied,
  tasksRestored,
  tempListCleared,
  taskDeleted,
  itemMenuClosed,
  menusClosed,
  deleteConfirmOpened,
  deleteConfirmClosed,
  setActiveTask,
  setIsEditing,
  setFilterText,
  setFilterDone,
} = slice.actions;

export default slice.reducer;

export const fetchTasks = () => async (dispatch) => {
  try {
    const res = await axios_.get(apiEndpoints.todo.list);
    dispatch(taskListReceived({ taskList: res.data }));
  } catch (error) {
    dispatch(taskListReceived({ taskList: [] }));
  }
};

export const clickTask = (task) => async (dispatch) => {
  dispatch(menusClosed());
  dispatch(doneToggled({ taskId: task.id }));

  try {
    await axios_.patch(apiEndpoints.todo.update(task.id), { done: !task.done });
    // mytoast.success(toastMessages.toggleComplete.success);
  } catch (error) {
    mytoast.danger(toastMessages.toggleComplete.error);
    dispatch(doneToggled({ taskId: task.id }));
  }
};

export const addTask = (task) => async (dispatch) => {
  dispatch(tasksCopied());
  dispatch(taskAdded({ text: task.text }));

  try {
    await axios_.post(apiEndpoints.todo.create, task);
    mytoast.success(toastMessages.createTask.success);
    dispatch(fetchTasks());
    dispatch(tempListCleared());
  } catch (error) {
    mytoast.danger(toastMessages.createTask.error);
    dispatch(tasksRestored());
  }
};

export const deleteTask = (task) => async (dispatch) => {
  dispatch(itemMenuClosed());
  dispatch(tasksCopied());
  dispatch(taskDeleted({ taskId: task.id }));

  try {
    await axios_.delete(apiEndpoints.todo.delete(task.id));
    mytoast.success(toastMessages.deleteTask.success);
    dispatch(tempListCleared());
  } catch (error) {
    mytoast.danger(toastMessages.deleteTask.error);
    dispatch(tasksRestored());
  }
};

export const editingOn = () => (dispatch) => {
  dispatch(setIsEditing(true));
};

export const editingOff = () => (dispatch) => {
  dispatch(setIsEditing(false));
};

export const updateTask = (task) => async (dispatch) => {
  try {
    await axios_.patch(apiEndpoints.todo.update(task.id), task);
    mytoast.success(toastMessages.updateTask.success);
    dispatch(fetchTasks());
  } catch (error) {
    mytoast.danger(toastMessages.updateTask.error);
  }
};
